import axios from "axios";
let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
  if (error.response.status === 401 && !refresh) {
    refresh = true;
    const userData = JSON.parse(localStorage.getItem('user'))
    if(userData!=null){
      const response = await   
      axios.post('http://localhost:8000/token/refresh/', {      
                      refresh: userData.data.refresh_token
                  }, 
                  { headers: 'Content-Type: application/json' },{withCredentials: true});

      if (response.status === 200) {
        axios.defaults.headers.common['Authorization'] = `Bearer 
        ${response.data['access']}`;
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        return axios(error.config);
      }else{
        alert("Usuario y contraseña incorrectos.")
      }
    }else{
      alert("Usuario y contraseña incorrectos.")
    }
    
  }
  refresh = false;
  
  return error;
});