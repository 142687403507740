/**  
 * 
 * Category puede ser []
 * Type puede ser { precios, productos }
 * 
 * **/

import Dropdown from './Dropdown'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Checkbox = (props) => {
	return <input type="checkbox" checked={props.val} onClick={() => {props.setValue(!props.val)}} />
}
const FormSelectOptions = ({tiendas, setTiendas, category, categoryList, setCategory, setType, setMisProductos}) => {

	return <div>
        <div className="form-group">
            <Dropdown
                options={tiendas}
                onSelect={setTiendas}
                label="Tienda"/>
        </div>
        <div className="form-group">
            <Dropdown
                options={categoryList}
                onSelect={setCategory}
                label="Categoría"/>
        </div>
        { /* <div className="form-group">
            <Dropdown
                options={['Productos', 'Precios']}
                onSelect={setType}
                label="Tipo de Relevamiento"/>
        </div> */ }

        <div className="form-group">
            <Dropdown
                options={{
                    0: 'Sólo mis productos',
                    1: 'Categoría completa'
                }}
                onSelect={setMisProductos}
                label="Clase de relevamiento"/>
        </div>
    </div>;
}

export default FormSelectOptions;