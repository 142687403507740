import logo from './logo.svg';
import './css/App.css';

import {BrowserRouter, Routes, Route} from 'react-router-dom'

import { AuthProvider } from "./hooks/useAuth";

import { Login } from './screens/Login';
import { Form } from './screens/Form';
import { ProtectedRoute } from './components/ProtectedRoute';
import {ToastContainer} from "react-custom-alert";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Navigation></Navigation> */}
        <AuthProvider>

          <Routes>
            <Route path="/" element={<ProtectedRoute><Form /></ProtectedRoute>}/>
            <Route path="/login" element={<Login/>}/>
            {/* <Route path="/logout" element={<Logout/>}/> */}
          </Routes>
        </AuthProvider>

      </BrowserRouter>
      
    </div>
  )
}

export default App;
