// Import the react JS packages 
import axios from "axios";
import React, {useState} from "react";
import { useAuth } from "../hooks/useAuth";
import NavBar from "../components/NavBar";
import "../css/Login.css"
import logo from "../img/login-logo.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

// Define the Login function.
export const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const { login } = useAuth();

    async function checkUsuarioRepositor(access)  {
        if(access === null){
            window.location.href = '/login'
        }else{
            try {
               const result = await axios.get(process.env.REACT_APP_BACK_API_URL + 'token/login/usuario', {
                headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access}`
                    }
                });

                return result.status === 200;
            } catch (e) {
                return false;
            }
      }
    }

    // Create the submit method.
      const submit = e => {
        e.preventDefault();
        const user = {
            username: username,
            password: password
            };
        // Create the POST requuest
        console.log(process.env.REACT_APP_BACK_API_URL + 'token/')
        axios.post(process.env.REACT_APP_BACK_API_URL + 'token/',
          user,
          {headers: { 'Content-Type': 'application/json'},
              validateStatus: (status) => status === 200})
            .then(async result => {
              if(result.status===200){
                const statusIsValid = await checkUsuarioRepositor(result.data['access']);
                if(statusIsValid){
                    await login( result.data );
                    axios.defaults.headers.common['Authorization'] =
                                              `Bearer ${result.data['access']}`;
                }else{
                    console.log("Es usuario pero no repositor.")
                }
              }
            })
          .catch((e) => console.log(e));
    }

    const styles = {
          container: {
            position: 'relative',
            width: '100%',
          },
          icon: {
            position: 'absolute',
            top: '40%',
            right: '15px',
            transform: 'translateY(-50%)',
          },
          input: {
            width: '100%',
              fontWeight: '600',
            padding: '10px 10px 10px 20px',
            borderRadius: '10px',
            border: '1px solid #ccc',
            backgroundColor: 'transparent',
            outline: 'none',
              fontFamily: 'Nunito Sans'
          },
          rowContainer: {
              marginLeft: '0px', marginRight: '0px', height: '100%'
          }
        };


    return(<>
        <div className="row rowContainer" style={styles.rowContainer}>
            <div className="col-md-5 d-none d-sm-none d-md-block">
                <div className="login-leftColumn">
                    <h2>Logo NexDato</h2>
                    <h5>Te damos la bienvenida!</h5>
                </div>
            </div>
            <div className="col-md-7 col-sm-12 d-block">
                <div className=" login-rightColumn">
                    <div className="login-page">

                        <div className="form">
                            <img src={logo}/>
                            <h4>NexDato</h4>
                            <form className="login-form" onSubmit={submit}>

                                <div style={styles.container}>
                                    <FontAwesomeIcon icon={faUser} style={styles.icon}/>
                                    <input type="text"
                                           style={styles.input}
                                           placeholder="Usuario"
                                           required
                                           value={username}
                                           onChange={e => setUsername(e.target.value)}
                                    />
                                </div>
                                <div style={styles.container}>
                                    <FontAwesomeIcon icon={faLock} style={styles.icon}/>
                                    <input type="password"
                                           style={styles.input}
                                           placeholder="Contraseña"
                                           required
                                           value={password}
                                           onChange={e => setPassword(e.target.value)}
                                    />
                                </div>
                                <p className="message">¿Olvidaste tu contraseña? <a href="#">Click aquí</a></p>
                                <button>Ingresar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </>)
}