
export function checkUploadImages(files){
    if(files.length>6){
        return "Subiste más de 6 imágenes."
    }

    if(areAllFilesPano(files)){
        return "No podes subir todas imágenes panorámicas."
    }

    return false;
}


function areAllFilesPano(fileList) {
  return Array.from(fileList).every(file => {
    const extension = file.name.split('.').pop().toLowerCase();
    return extension === 'pano';
  });
}