import React, { useState, useEffect } from 'react';

const PreviewImgs = ({urls, boxes}) => {

    const [classColumnsFoto, setClassColumnsFoto ] = useState("")

    useEffect(() => {
        if(urls.length > 4){
               setClassColumnsFoto("col-4")
           }else if(urls.length > 1){
               setClassColumnsFoto("col-md-6 col-4")
           }else{
               setClassColumnsFoto("col-9")
           }
        urls.forEach(({ url, file }, index) => {
          const img = document.getElementById(`img-${index}`);
          const canvas = document.getElementById(`canvas-${index}`);
          const ctx = canvas.getContext('2d');

          img.onload = function() {
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw boxes on the canvas after the image has loaded
            ctx.fillStyle = 'rgba(255, 0, 0, 0.5)'; // Red with 50% opacity
            boxes[Object.keys(boxes)[index]].bboxes.forEach(box => {
              const x = box.x * canvas.width;
              const y = box.y * canvas.height;
              const w = box.w * canvas.width;
              const h = box.h * canvas.height;
              ctx.fillRect(x, y, w, h);
            });
          };
        });
      }, [urls, boxes, classColumnsFoto, setClassColumnsFoto]);



  return(<div style={{maxWidth:'40%'}} className="previewImgs">


      <div className="row preview">
        {urls.map((url, index) => (
            <div key={index} style={{position: 'relative', marginTop: '10px'}} className={classColumnsFoto}>
              <canvas id={`canvas-${index}`} style={{ position:'absolute', left:10, top:0, maxWidth: '100%' }} />
              <img id={`img-${index}`} style={{ margin:0}} key={index} src={url} alt={`Preview ${index + 1}`} />
              </div>
        ))}
      </div>
    </div>)
  };


export default PreviewImgs;