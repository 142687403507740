import React, { useState } from 'react';
import '../css/Modal.css'; // Your custom styles

function ConfirmModal({ isOpen, onClose, onConfirm, dataSKU }) {
  if (!isOpen) return null;
  console.log(dataSKU)

  return (
    <div className="modal-overlay">
      <div className="modal-content">
          <h6 style={{textAlign: 'left', fontWeight: "bold", fontFamily:"Nunito Sans"}}>Relevamiento cargado</h6>

          <table style={{fontFamily:"Nunito Sans", fontSize: "14px", marginBottom: 20}}>
              <tr style={{marginBottom: "10px"}}>
                  <td style={{textAlign: "left"}}>Producto</td>
                  <td>Cantidad</td>
                  <td>Precio</td>
              </tr>
              {
                  Object.keys(dataSKU.skus_cantidad).map((key) => (
                      <tr key={key}>
                          <td style={{textAlign: "left"}}>{key}</td>
                          <td>{dataSKU.skus_cantidad[key]}</td>
                          <td>${dataSKU.skus_precios[key]}</td>
                      </tr>

                    ))

              }
          </table>
          <div style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexDirection: 'row',
              marginTop: 20
          }}>
              <span
                style={{
                        minWidth: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'nunito sans',
                        fontWeight: 700
                }}>¿Deseas guardar?</span>
              <button className="cancel" onClick={onClose}>Cancelar</button>
              <button className="success" onClick={onConfirm}>Aceptar</button>
          </div>

      </div>
    </div>
  );
}

export default ConfirmModal;