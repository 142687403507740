import React, { useState, useEffect } from 'react';
import axios from "axios";
import '../interceptors/axios';

import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";


import FormSelectOptions from '../components/FormSelectOptions';
import ImageUploader from '../components/ImageUploader';
import '../css/Form.css';
import PreviewImgs from "../components/PreviewImgs";
import Compressor from "compressorjs";
import NavBar from "../components/NavBar";
import { ToastContainer, toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; // import css file from root.
import logo from "../img/login-logo.png"
import ConfirmModal from "../components/ConfirmModal";

import {checkUploadImages} from "../utils/utils"

export const Form = () => {
  const { logout } = useAuth();

  const [logged, setLogged] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSKU, setDataSKU] = useState({})


  const [formFinished, setFormFinished] = useState(false)
  const [usernameStr, setUsernameStr] = useState("")

  const [tienda, setTienda] = useState(1)
  const [category, setCategory] = useState(false)
  const [misProductos, setMisProductos] = useState(false)
  const [secondSelection, setType] = useState(false)

  const [categoryList, setCategoryList] = useState({})
  const [tiendaList, setTiendaList] = useState({})

  /** estados para administrar imágenes **/
  const [images, setImages] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);

  const [compressedFile, setCompressedFile] = useState(null);

  const [showPreview, setShowPreview] = useState(false)
  const [data, setData] = useState([]);
  const [boxes, setBoxes] = useState([]);

  const [idAuditoria, setIdAuditoria] = useState(-1)

  const [loadingFotos, setLoadingFotos] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'))
    const access = userData.access
    const refresh = userData.refresh

    if(access === null){                   
        window.location.href = '/login'
    }else{
      (async () => {
        try {
          await axios.get(   
                          process.env.REACT_APP_BACK_API_URL + 'token/login/usuario', {
                          headers: {
                              'Content-Type': 'application/json',
                              'Authorization': `Bearer ${access}`
                          }}
                        ).then(result => { 

                          let tiendas={}
                          let categorias={}
                          setUsernameStr(result.data.user);
                          result.data.tiendas.map((t) => {
                            tiendas[t.id] = t.nombre
                          })
                          setTiendaList(tiendas)
                          result.data.categorias.map((t) => {
                            categorias[t.id] = t.nombre
                          })
                          setCategoryList(categorias)

                          setCategory(result.data.categorias[0].id)
                        
                        });

        } catch (e) {
          console.log(e)
        }
      })()};
  }, []);


  const handleSubmit = (e) => {
    setIsModalOpen(false)
    const userData = JSON.parse(localStorage.getItem('user'))
    const access = userData.access
    try{
      axios.post(
        // tienda_id, repositor_id
        process.env.REACT_APP_BACK_API_URL + 'api-interna/aprobar_relevamiento/'+idAuditoria, {}, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${access}`
            }
        }).then((response) => {
          toast.success('La auditoría fue confirmada', {floatingTime: 2500})
          setBoxes({})
          setShowPreview(false)
          setLoadingFotos(false)
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 1500)
        }).catch((e) => console.log(e));

    } catch(e){
      toast.error('Ocurrió un error durante la confirmación.', {floatingTime: 2500})

      console.log(e)
    }

  };

  const handleImageChange = async (e) => {
    setShowPreview(false)
    setLoadingFotos(!loadingFotos)
    toast.info('Se están procesando las fotos. Aguarda por favor ..',  {toastStyle: {boxShadow: 'none!important'}, floatingTime: 3500})

    if(checkUploadImages(e.target.files)){
      alert(checkUploadImages(e.target.files));
      return null;
    }

    const files = Array.from(e.target.files);
    files.map(f => handleCompressedUpload(f))
    setImages(files);

    // preview
    const urls = files.map(file => URL.createObjectURL(file));
    setPreviewUrls(urls);

    try{
      const userData = JSON.parse(localStorage.getItem('user'))
      const access = userData.access
      var formData = new FormData();
      files.map((image) => {
        formData.append("files", image)
      })
      formData.append("categoria", category)
      formData.append("categoria_completa", misProductos)
      axios.post(
        process.env.REACT_APP_BACK_API_URL + 'api-interna/relevamiento/'+tienda, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${access}`
            }
        }).then((response) => {

          if(response.status===201){
            setDataSKU(response.data)
            toast.success('Chequea que los productos se hayan identificado correctamente y luego presiona Guardar para completar la operación', {floatingTime: 2500})
            setIdAuditoria(response.data.id_relevamiento)
            setBoxes(response.data.info_productos_por_img)
            setShowPreview(true)
            setLoadingFotos(false)
          }else{
            toast.error("El relevamiento produjo un error, verificá la imagen e intentá de nuevo.",{floatingTime: 4000})
            setLoadingFotos(false)
          }
        }).catch((e) => console.log(e));
      } catch(e){
        console.log(e)
      }

  };

   const handleCompressedUpload = (image) => {
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        return compressedResult
      },
    });
  };


   const styles = {
          box: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
            zIndex: 1,
            background: '#FFFFFF',
            maxWidth: '370px',
            minHeight: '600px',
            margin: '0 auto',
            padding: '15px',
            borderRadius: '10px',
            textAlign: 'center',
            boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)"
          },
          container: {
            position: 'relative',
            width: '100%',
            display: "flex",
            height: "100%",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          },
          icon: {
            position: 'absolute',
            top: '40%',
            right: '15px',
            transform: 'translateY(-50%)',
          },
          input: {
            width: '100%',
              fontWeight: '600',
            padding: '10px 10px 10px 20px',
            borderRadius: '10px',
            border: '1px solid #ccc',
            backgroundColor: 'transparent',
            outline: 'none',
              fontFamily: 'Nunito Sans'
          },
        };

   if(showPreview){
     styles.container = {
      display: "flex"
     }
   }else{
    styles.container = {
      display: "block"
     }
   }


  return (  <>
    <NavBar username={usernameStr} logout={logout}/>
    <div className='container formContainer' style={styles.container}>
      <div style={styles.box}>
        <div className="row justify-content-md-center">
          <div className="">
            <img style={{
              width: '35%', marginBottom: 20
            }} src={logo}/>
            <FormSelectOptions
                whenFinish={setFormFinished}
                tiendas={tiendaList}
                setTiendas={setTienda}
                category={category}
                categoryList={categoryList}
                setCategory={setCategory}
                setType={setType}
                setMisProductos={setMisProductos}
            />
          </div>

          <div className="col-lg-12 col-12">
            <div className="upload-container">
              {showPreview && <><button style={{fontFamily:'Nunito Sans, sans-serif', backgroundColor:'#21BDA2', width: '100%', marginBottom: '10px', borderRadius: '10px', fontWeight: 'bold', color: '#1F1F39'}}
                                        onClick={() => setIsModalOpen(true)} type="submit">Guardar</button></>}

              <ConfirmModal
                isOpen={isModalOpen}
                onConfirm={handleSubmit}
                onClose={() => setIsModalOpen(false)}
                dataSKU={dataSKU}
              />

              <input
                  name='fotos'
                  id='fotos'
                  type="file"
                  multiple
                  accept="image/*"
                  style={styles.input}
                  onChange={handleImageChange}
                  onClick={e => (e.target.value = null)}
                  hidden
                  disabled={loadingFotos}
              />
              <label style={{width:'100%', fontFamily:'Nunito Sans, sans-serif', backgroundColor: "#2C2D5B", fontWeight: 600}} htmlFor="fotos">
                {loadingFotos
                    ? <div className="fa-1x"><i className="fas fa-spinner fa-spin"></i></div>
                    : "Cargar fotos"
                }
              </label>

            </div>
          </div>
        </div>
      </div>
      {showPreview && <PreviewImgs
              urls={previewUrls}
              boxes={boxes} /> }
    </div>
  <ToastContainer floatingTime={2000} />

  </>);
};
