import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

const Dropdown = ({ options, onSelect, label }) => {
const styles = {
  container: {
    margin: '20px',
  },
  inputContainer: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    top: '10%',
    left: '35px',
    transform: 'translateY(-50%)',
    color: '#aaa',
  },
  input: {
    width: '100%',
    padding: '20px 10px 20px 10px',
    borderRadius: '10px',
    border: '1px solid #ccc',
    backgroundColor: 'transparent',
    outline: 'none',
    fontSize: '13px',
  },
  label: {
    position: 'absolute',
    top: '0',
    left: '15px',
    transform: 'translateY(-50%)',
    backgroundColor: '#fff',
    color: '#2C2D5B',
    fontFamily: 'Nunito Sans',
    padding: '0 5px',
    transition: '0.3s',
    pointerEvents: 'none',
    fontSize: '13px',
  },
};
  return (
      <div style={styles.container}>
        <div style={styles.inputContainer}>
          <Form.Select style={styles.input} onChange={(v) => onSelect(v.target.value)} aria-label={label}>
            {Object.entries(options).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
            ))}
          </Form.Select>
          <label style={styles.label}>{label}</label>
        </div>
      </div>


)
  ;
};

export default Dropdown;