import React, { useState } from 'react';


const NavBar = ({username, logout}) => {

    return (
      <nav className="navbar navbar-dark  navbar-expand-lg" style={{ width:"100%", backgroundColor: '#1F1F39!important',marginBottom: 10, justifyContent: 'space-between', padding: '10px'}}>
          <a  className="navbar-brand" href="#">NexDato</a>
          {username!=="" && <span className="navbar-text">
              Hola {username} - <a href="#" onClick={() => logout()}>Salir</a>
            </span>}
      </nav>
  );
};
export default NavBar;